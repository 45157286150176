<template>
  <div>
    <b-modal
      ref="mdlClienteFormulario"
      :title="title"
      ok-title="Salvar"
      cancel-title="Cancelar"
      size="lg"
      @ok.prevent="salvar"
      @hidden="limpar"
      @shown="validacao"
      centered
    >
      <form ref="frmClienteFormulario">
        <b-row>
          <b-col cols="9">
            <b-form-group label="Logomarca:" label-for="cli-logomarca">
              <b-form-file
                id="cli-logomarca"
                name="logomarca"
                accept="image/jpeg,image/png"
                size="sm"
                placeholder="Escolha um arquivo ou arraste aqui"
                drop-placeholder="Solte o arquivo aqui"
                browse-text="Procurar"
                @change="obterLogomarca"
              ></b-form-file>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label-for="cli-visualizar-logomarca">
              <template #label> &nbsp; </template>

              <b-button
                id="cli-visualizar-logomarca"
                variant="primary"
                size="sm"
                block
                @click="exibirLogomarca(formulario.logomarca)"
                :disabled="!formulario.logomarca"
              >
                Visualizar logomarca
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Razão social:" label-for="cli-razao-social">
              <b-form-input
                id="cli-razao-social"
                name="razaoSocial"
                v-model="formulario.razaoSocial"
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Nome fantasia:" label-for="cli-nome-fantasia">
              <b-form-input
                id="cli-nome-fantasia"
                name="nomeFantasia"
                v-model="formulario.nomeFantasia"
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="4">
            <b-form-group label="CPF/CNPJ:" label-for="cli-cpf-cnpj">
              <b-form-input
                id="cli-cpf-cnpj"
                name="cpfCnpj"
                v-model="formulario.cpfCnpj"
                size="sm"
                data-inputmask="'mask': ['999.999.999-99', '99.999.999/9999-99']"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="3">
            <b-form-group label="Telefone:" label-for="cli-telefone">
              <b-form-input
                id="cli-telefone"
                name="telefone"
                v-model="formulario.telefone"
                size="sm"
                data-inputmask="'mask': ['(99) 9999-9999', '(99) 99999-9999']"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="E-mail:" label-for="cli-email">
              <b-form-input
                id="cli-email"
                name="email"
                v-model="formulario.email"
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="3">
            <b-form-group label="CEP:" label-for="cli-cep">
              <b-form-input
                id="cli-cep"
                name="cep"
                v-model="formulario.cep"
                size="sm"
                data-inputmask="'mask': '99999-999'"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Logradouro:" label-for="cli-logradouro">
              <b-form-input
                id="cli-logradouro"
                name="logradouro"
                v-model="formulario.logradouro"
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="3">
            <b-form-group label="Número:" label-for="cli-numero">
              <b-form-input
                id="cli-numero"
                name="numero"
                v-model="formulario.numero"
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Complemento:" label-for="cli-complemento">
              <b-form-input
                id="cli-complemento"
                name="complemento"
                v-model="formulario.complemento"
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Bairro:" label-for="cli-bairro">
              <b-form-input
                id="cli-bairro"
                name="bairro"
                v-model="formulario.bairro"
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="4">
            <b-form-group label="Estado:" label-for="cli-estado">
              <b-form-select
                v-model="formulario.uf"
                :options="estados"
                size="sm"
                text-field="nome"
                value-field="sigla"
              >
                <template #first>
                  <b-form-select-option value=""
                    >-- Selecione um estado --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Cidade:" label-for="cli-cidade">
              <b-form-select
                v-model="formulario.cidade"
                :options="cidadesPorEstado"
                size="sm"
                text-field="nome"
                value-field="nome"
              >
                <template #first>
                  <b-form-select-option value=""
                    >-- Selecione uma cidade --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <cliente-logomarca ref="mdlClienteLogomarca"></cliente-logomarca>
  </div>
</template>

<script>
import ClienteService from "@/core/services/cliente.service";
import ClienteLogomarca from "./ClienteLogomarca.vue";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import Swal from "sweetalert2";
import Inputmask from "inputmask";

import estados from "@/core/data/estados";
import cidades from "@/core/data/cidades";

export default {
  name: "cliente-formulario",

  components: {
    ClienteLogomarca,
  },

  data() {
    return {
      formulario: {
        id: "",
        cpfCnpj: "",
        razaoSocial: "",
        nomeFantasia: "",
        logradouro: "",
        numero: "",
        bairro: "",
        cidade: "",
        uf: "",
        complemento: "",
        cep: "",
        telefone: "",
        email: "",
        logomarca: "",
      },
    };
  },

  methods: {
    exibirModal(cliente) {
      if (cliente) {
        this.formulario.id = cliente.id;
        this.formulario.cpfCnpj = cliente.cpf_cnpj;
        this.formulario.razaoSocial = cliente.razao_social;
        this.formulario.nomeFantasia = cliente.nome_fantasia;
        this.formulario.logradouro = cliente.logradouro;
        this.formulario.numero = cliente.numero;
        this.formulario.bairro = cliente.bairro;
        this.formulario.cidade = cliente.cidade;
        this.formulario.uf = cliente.uf;
        this.formulario.complemento = cliente.complemento;
        this.formulario.cep = cliente.cep;
        this.formulario.telefone = cliente.telefone;
        this.formulario.email = cliente.email;
      }

      this.$refs.mdlClienteFormulario.show();
    },

    async salvar() {
      await this.fv.validate().then((status) => {
        if (status === "Valid") {
          ClienteService.salvar(this.formulario)
            .then(() => {
              this.$emit("atualizar");
              this.$refs.mdlClienteFormulario.hide();

              Swal.fire({
                icon: "success",
                title: "Sucesso!",
                text: "Cliente salvo com sucesso.",
              });
            })
            .catch((error) => {
              if (error.response.status === 422) {
                Object.entries(error.response.data.errors).forEach(
                  ([key, value]) => {
                    this.fv
                      .updateValidatorOption(key, "blank", "message", value[0])
                      .updateFieldStatus(key, "Invalid", "blank");
                  }
                );
              }
            });
        }
      });
    },

    validacao() {
      const frmClienteFormulario = this.$refs.frmClienteFormulario;
      Inputmask().mask(frmClienteFormulario.querySelectorAll("input"));

      this.fv = formValidation(frmClienteFormulario, {
        fields: {
          razaoSocial: {
            validators: {
              notEmpty: {
                message: "Preenchimento obrigatório.",
              },
              blank: {},
            },
          },
          logomarca: {
            validators: {
              callback: {
                message: "Preenchimento obrigatório.",
                callback: () => {
                  return !!this.formulario.id;
                },
              },
              file: {
                extension: "jpg,jpeg,png",
                type: "image/jpeg,image/png",
                message: "O arquivo deve ser uma imagem (JPG, JPEG ou PNG).",
              },
              blank: {},
            },
          },
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap(),
        },
      });
    },

    limpar() {
      this.formulario.id = "";
      this.formulario.cpfCnpj = "";
      this.formulario.razaoSocial = "";
      this.formulario.nomeFantasia = "";
      this.formulario.logradouro = "";
      this.formulario.numero = "";
      this.formulario.bairro = "";
      this.formulario.cidade = "";
      this.formulario.uf = "";
      this.formulario.complemento = "";
      this.formulario.cep = "";
      this.formulario.telefone = "";
      this.formulario.email = "";
      this.formulario.logomarca = "";
    },

    exibirLogomarca(logomarca) {
      this.$refs.mdlClienteLogomarca.exibirLogomarca(logomarca);
    },

    obterLogomarca(event) {
      if (event.target.files.length) {
        this.formulario.logomarca = event.target.files[0];
      } else {
        this.formulario.logomarca = "";
      }
    },
  },

  computed: {
    title() {
      return this.formulario.id ? "Editar cliente" : "Novo cliente";
    },

    estados() {
      return estados;
    },

    cidadesPorEstado() {
      return cidades.filter((cidade) => cidade.uf === this.formulario.uf);
    },
  },
};
</script>
